<template>
  <Banner
    :backgroundImage="$maxxpm.config.availability.banner.image"
    :backgroundVideo="$maxxpm.config.availability.banner.video"
    class="pt-6 pb-6 text-center"
  >
    <h1 class="text-white display-1">Available Units</h1>
    <p class="text-white display-7 mt-4">Unit availability is updated often from Monday-Friday during business hours. Evenings and weekends may not reflect accurate availability dates.</p>
  </Banner>
  <div class="grid container">
    <div
      class="col-12 text-center"
      v-if="loadingUnits"
    >
      <i class="pi pi-spin pi-spinner units-loading"></i>
    </div>
    <div
      class="col-12"
      v-else-if="$maxxpm.config.availability.hasWaitingList && !postedUnits.length"
    >
      <p class="text-center text-xl mb-0">Sorry, there are no available units at this time.</p>
      <iframe
        :id="`JotFormIFrame-${$maxxpm.config.availability.waitingListJotformId}`"
        title="Vista View Waiting List"
        onload="window.parent.scrollTo(0,0)"
        allowtransparency="true"
        allowfullscreen="true"
        allow="geolocation; microphone; camera"
        :src="`https://form.jotform.com/${$maxxpm.config.availability.waitingListJotformId}`"
        frameborder="0"
        style="min-width: 100%; max-width: 100%; height: 539px; border: none"
        scrolling="no"
      >
      </iframe>
    </div>
    <div
      class="listings-container"
      v-else
    >
      <UnitListing
        v-for="unit in postedUnits"
        :key="unit.id"
        :unit="unit"
        source="vistaviewcondorentals.com"
      />
    </div>
  </div>
</template>

<script setup>
import { ref, inject, onBeforeMount } from "vue";
import { UnitListing, Banner } from "@maxxpm/vue-components";

import axios from "axios";

const $maxxpm = inject("$maxxpm");

const postedUnits = ref([]);
const loadingUnits = ref(true);

onBeforeMount(async () => {
  const posted = await axios.get("https://cdn.maxxpm.com/posted_units.json");
  postedUnits.value = posted.data.filter((unit) => unit.property_groups && unit.property_groups.includes($maxxpm.config.availability.propertyGroup));

  let rentlyUnits = await axios.get("https://cdn.maxxpm.com/rently_units.json");

  postedUnits.value = postedUnits.value.map((unit) => {
    const rently = rentlyUnits.data.find((rentlyUnit) => rentlyUnit["unit#"] === unit.rentable_uid);
    if (rently) {
      if (!rently.status_title.includes("Inactive")) {
        unit.rently_id = rently.id;
      }

      unit.auto_showing = rently.status_title.includes("Auto Showing");
      unit.agent_showing = rently.status_title.includes("Agent Showing");
    }
    return unit;
  });

  loadingUnits.value = false;
  if (!postedUnits.value.length) {
    loadJotform();
  }
});

function loadJotform() {
  let iframe;
  var ifr = document.getElementById(`JotFormIFrame-${$maxxpm.config.availability.waitingListJotformId}`);
  if (ifr) {
    var src = ifr.src;
    var iframeParams = [];
    if (window.location.href && window.location.href.indexOf("?") > -1) {
      iframeParams = iframeParams.concat(window.location.href.substr(window.location.href.indexOf("?") + 1).split("&"));
    }
    if (src && src.indexOf("?") > -1) {
      iframeParams = iframeParams.concat(src.substr(src.indexOf("?") + 1).split("&"));
      src = src.substr(0, src.indexOf("?"));
    }
    iframeParams.push("isIframeEmbed=1");
    ifr.src = src + "?" + iframeParams.join("&");
  }
  window.handleIFrameMessage = function (e) {
    if (typeof e.data === "object") {
      return;
    }
    var args = e.data.split(":");
    if (args.length > 2) {
      iframe = document.getElementById("JotFormIFrame-" + args[args.length - 1]);
    } else {
      iframe = document.getElementById("JotFormIFrame");
    }
    if (!iframe) {
      return;
    }
    switch (args[0]) {
      case "scrollIntoView":
        iframe.scrollIntoView();
        break;
      case "setHeight":
        iframe.style.height = args[1] + "px";
        if (!isNaN(args[1]) && parseInt(iframe.style.minHeight) > parseInt(args[1])) {
          iframe.style.minHeight = args[1] + "px";
        }
        break;
      case "collapseErrorPage":
        if (iframe.clientHeight > window.innerHeight) {
          iframe.style.height = window.innerHeight + "px";
        }
        break;
      case "reloadPage":
        window.location.reload();
        break;
      case "loadScript":
        if (!window.isPermitted(e.origin, ["jotform.com", "jotform.pro"])) {
          break;
        }
        var src = args[1];
        if (args.length > 3) {
          src = args[1] + ":" + args[2];
        }
        var script = document.createElement("script");
        script.src = src;
        script.type = "text/javascript";
        document.body.appendChild(script);
        break;
      case "exitFullscreen":
        if (window.document.exitFullscreen) window.document.exitFullscreen();
        else if (window.document.mozCancelFullScreen) window.document.mozCancelFullScreen();
        else if (window.document.mozCancelFullscreen) window.document.mozCancelFullScreen();
        else if (window.document.webkitExitFullscreen) window.document.webkitExitFullscreen();
        else if (window.document.msExitFullscreen) window.document.msExitFullscreen();
        break;
    }
    var isJotForm = e.origin.indexOf("jotform") > -1 ? true : false;
    if (isJotForm && "contentWindow" in iframe && "postMessage" in iframe.contentWindow) {
      var urls = {
        docurl: encodeURIComponent(document.URL),
        referrer: encodeURIComponent(document.referrer),
      };
      iframe.contentWindow.postMessage(JSON.stringify({ type: "urls", value: urls }), "*");
    }
  };
  window.isPermitted = function (originUrl, whitelisted_domains) {
    var url = document.createElement("a");
    url.href = originUrl;
    var hostname = url.hostname;
    var result = false;
    if (typeof hostname !== "undefined") {
      whitelisted_domains.forEach(function (element) {
        if (hostname.slice(-1 * element.length - 1) === ".".concat(element) || hostname === element) {
          result = true;
        }
      });
      return result;
    }
  };
  if (window.addEventListener) {
    window.addEventListener("message", window.handleIFrameMessage, false);
  } else if (window.attachEvent) {
    window.attachEvent("onmessage", window.handleIFrameMessage);
  }
}
</script>

<style scoped>
.listings-container {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  justify-content: center;
}

.units-loading {
  color: var(--mdb-secondary);
  font-size: 3rem;
  margin: 3rem 0;
}

.container {
  width: 95vw;
  max-width: 1200px;
  margin: auto;
}
</style>
